import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import {
  addBlockInfo, deleteBlockInfo,
  getBlockInfo,
} from "../services/blockInfoServices";

const initialState = {
  blockInfoState: {},
  blockInfoState: [],
  blockInfoIsLoading: false,
  addBlockInfoLoading: false
};

export const blockInfoGet = createAsyncThunk(
  "getBlockInfo",
  async (payload) => {
    return await getBlockInfo(payload);
  }
);

export const AddBlockInfo = createAsyncThunk(
  "AddBlockInfo",
  async (payload) => {
    return await addBlockInfo(payload);
  }
);

export const DeleteBlockInfo = createAsyncThunk(
  "/deleteBlockInfo",
  async (payload) => {
    return await deleteBlockInfo(payload);
  }
);

const blockInfoSlice = createSlice({
  name: "blockInfoSlice",
  initialState,
  reducers: {
    clearBlockInfo: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(blockInfoGet.pending, (state, action) => {
      state.blockInfoIsLoading = true;
    });
    builder.addCase(blockInfoGet.fulfilled, (state, action) => {
      state.blockInfoIsLoading = false;
      state.blockInfoState = action.payload;
    });
    builder.addCase(blockInfoGet.rejected, (state, action) => {
      state.blockInfoIsLoading = false;
    });
  },
});
export default blockInfoSlice.reducer;
export const { clearBlockInfo, blockInfoIsLoading } = blockInfoSlice.actions;

export const selectBlockInfoState = (state) =>
  state.blockInfo.blockInfoState;

export const useBlockInfoState = () => {
  const blockInfoState = useSelector(selectBlockInfoState);
  return useMemo(() => blockInfoState, [blockInfoState]);
};

