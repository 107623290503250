import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "reactstrap";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../../helperFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ControlledInput from "../../Forms/Controller/ControlledInput";
import { payoutBlockInfoGetAdd } from "../../../../redux/slices/payoutBlockInfoSlice";

const PayoutBlockInfoModal = (props) => {
  const { isModalOpen, onCloseModal, onGet } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const schema = Yup.object({
    acNumber: Yup.string().required("Please add account number."),
  });

  const initialValues = {
    acNumber: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    onCloseModal();
    setIsLoading(false);
    reset();
  };

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsLoading(true);
      const payload = {
        ...values,
      };
      const res = await dispatch(payoutBlockInfoGetAdd(payload)).unwrap();

      if (res) {
        await onGet();
        responseToaster(res);
        onClose();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  if (!isModalOpen) {
    return;
  }

  return (
    <Modal isOpen={isModalOpen} toggle={onClose} centered={true}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h3 className="modal-title mt-0">Add Payout Block Info</h3>
          <i onClick={onClose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="modal-body">
          <ControlledInput
            name="acNumber"
            label="Account Number"
            min={1}
            errors={errors}
            placeholder="Enter Account Number"
            control={control}
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button type="button" className="graybutton" onClick={onClose}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              isButtonLoading={isLoading}
              className="submitbutton"
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default PayoutBlockInfoModal;
