import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { getCustWhiteList, addCustWhiteList, editCustWhiteList, deleteCustWhiteList } from "../services/custWhiteListServices";

const initialState = {
  isLoading: true,
  data: [],
};

export const custWhiteListGet = createAsyncThunk("/custWhiteListGet", async (payload) => {
  return await getCustWhiteList(payload);
});

export const custWhiteListAdd = createAsyncThunk("/custWhiteListAdd", async (payload) => {
  return await addCustWhiteList(payload);
});

export const custWhiteListEdit = createAsyncThunk("/custWhiteListEdit", async (payload) => {
  return await editCustWhiteList(payload);
});

export const custWhiteListDelete = createAsyncThunk("/custWhiteListDelete", async (payload) => {
  return await deleteCustWhiteList(payload);
});

const custWhiteListSlice = createSlice({
  name: "custWhiteListSlice",
  initialState,
  reducers: {
    clearCustWhiteList: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(custWhiteListGet.fulfilled, (state, action) => {
      state.data = action.payload?.data || []
    });

  },
});
export default custWhiteListSlice.reducer;
export const { clearCustWhiteList } = custWhiteListSlice.actions;

export const selectCustWhiteListData = (state) => {
  return state.custWhiteList.data;
};
export const useCustWhiteListData = () => {
  const data = useSelector(selectCustWhiteListData);
  return useMemo(() => data, [data]);
};
