import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "reactstrap";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useDispatch, useSelector } from "react-redux";
import { capitalCaseLabel, responseToaster } from "../../../../helperFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";
import { Filter_Data_Transaction } from "../../../../redux/slices/transactionSlice";
import { AddBlockInfo } from "../../../../redux/slices/blockInfoSlice";
import ControlledInput from "../../Forms/Controller/ControlledInput";

const BlockInfoModal = (props) => {
  const { isModalOpen, onCloseModal, onGet } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [ddLoading, setDdLoading] = useState(false);
  const { transactionFilterState } = useSelector((state) => state?.transaction);
  const getFilterTransactions = async () => {
    try {
      setDdLoading(true);
      await dispatch(Filter_Data_Transaction()).unwrap();
      setDdLoading(false);
    } catch (err) {
      setDdLoading(false);
    }
  };
  const schema = Yup.object({
    merchantId: Yup.string().required("Please select merchant."),
    columnName: Yup.string().required("Please add column name."),
    blockData: Yup.string().required("Please add block data."),
  });

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    onCloseModal();
    setIsLoading(false);
    setDdLoading(false);
    reset();
  };

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsLoading(true);
      const payload = {
        ...values,
      };
      const res = await dispatch(AddBlockInfo(payload)).unwrap();
      if (res) {
        await onGet();
        responseToaster(res);
        onClose();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const prepareMerchantOption = () => {
    const options = transactionFilterState?.cilent_data
      ?.slice()
      ?.sort((a, b) => a?.merchantName?.localeCompare?.(b?.merchantName))
      ?.map((val) => {
        const clientDataOption = {
          value: val?.merchantId,
          label: val?.merchantName,
        };
        return clientDataOption;
      });
    return options;
  };

  const prepareFieldName = () => {
    const options = [
      { key: "browser_id" },
      { key: "customer_id" },
      { key: "customer_ip" },
      { key: "customer_email" },
      { key: "customer_mobile" },
      { key: "cust_state", value: "Customer State" },
      { key: "cust_city", value: "Customer City" },
      { key: "payment_data" },
    ]?.map?.((item) => {
      const clientDataOption = {
        value: item?.key,
        label: item?.value || capitalCaseLabel?.(item?.key),
      };
      return clientDataOption;
    });
    return options;
  };

  useEffect(() => {
    if (isModalOpen) {
      getFilterTransactions();
    }
  }, [isModalOpen]);

  if(!isModalOpen){
    return
  }

  return (
    <Modal isOpen={isModalOpen} toggle={onClose} centered={true}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h3 className="modal-title mt-0">Add Block Info</h3>
          <i onClick={onClose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="modal-body">
          <ControlledDropDown
            id="merchantId"
            name="merchantId"
            label="Merchant Name"
            isLoading={ddLoading}
            errors={errors}
            isDisabled={ddLoading}
            options={prepareMerchantOption()}
            control={control}
          />
          <ControlledDropDown
            id="columnName"
            name="columnName"
            label="Column Name"
            errors={errors}
            options={prepareFieldName()}
            control={control}
          />
          <ControlledInput
            name="blockData"
            label="Block Data"
            placeholder={"Enter Block Data"}
            control={control}
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button type="button" className="graybutton" onClick={onClose}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              isButtonLoading={isLoading}
              className="submitbutton"
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default BlockInfoModal;
