import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { custWhiteListAdd, custWhiteListEdit } from "../../../redux/slices/custWhiteListSlice";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { useForm } from "react-hook-form";
import { responseToaster } from "../../../helperFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const AddCustWhiteListModal = (props) => {
  const { onHide, isOpen, onGet } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    cust_id: "",
  };

  const validationSchema = Yup.object({
    cust_id: Yup.string().trim().nullable().required("Please Enter Customer ID"),
  });

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsLoading(true);
      const res = isOpen?.customer_id
        ? await dispatch(custWhiteListEdit({ old_cust_id: isOpen?.customer_id, ...values })).unwrap()
        : await dispatch(custWhiteListAdd(values)).unwrap();
      responseToaster(res);
      if (res?.status) {
        onClose();
        onGet();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isOpen?.customer_id) {
      setValue("cust_id", isOpen?.customer_id);
    }
  }, [isOpen]);

  const onClose = () => {
    reset();
    onHide();
  };

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!isOpen}
      toggle={onClose}
      centered={true}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h4 className="modal-title mt-0">
            {isOpen?.customer_id ? "Update" : "Add"} WhiteList Customer
          </h4>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          <Row>
            <ControlledInput
              name="cust_id"
              label="Customer ID"
              placeholder="Enter Customer ID"
              control={control}
              errors={errors}
            />

          </Row>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isLoading}
            >
              {isOpen?.customer_id ? "Update" : "Submit"}
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddCustWhiteListModal;
