import { Button, Card, CardHeader, Form } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import AddCustWhiteListModal from "./AddCustWhiteListModal";
import { useRole } from "../../../redux/slices/authSlice";
import DeleteModal from "../../../components/Custom/Modals/DeleteModal/DeleteModal";
import { custWhiteListDelete, custWhiteListGet, useCustWhiteListData } from "../../../redux/slices/custWhiteListSlice";
import { useForm } from "react-hook-form";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";

const initialValues = {
  cust_id: ""
};

const CustWhiteList = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false)
  const role = useRole("Cust Whitelist Info");
  const [isDeleteOpenModal, setIsDeleteOpenModal] = useState(false);
  const dispatch = useDispatch();

  const custWhiteList = useCustWhiteListData();
  const columns = [
    {
      title: "Customer ID",
      name: "customer_id",
    },
    {
      title: "Added By",
      name: "added_by",
    },
    {
      title: "DATE",
      name: "created_at_ist",
    },
    {
      title: "ACTIONS",
      name: "actions",
      Cell: (data) => {
        return (
          <>
            <div className="button-space d-flex">
              {role.includes("custWhitelistInfo-update") ? (
                <Button
                  title="Edit"
                  color="primary"
                  className="btn-smallsize"
                  onClick={() => {
                    setIsAddModalOpen(data);
                  }}
                >
                  Edit
                </Button>
              ) : null}
              {role.includes("custWhitelistInfo-delete") ? (
                <Button
                  title="Delete"
                  color="danger"
                  className="btn-smallsize"
                  onClick={() => setIsDeleteOpenModal(data?.customer_id)}
                >
                  Delete
                </Button>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  })

  const onDelete = async () => {
    try {
      setDeleteLoading(true);
      const payload = {
        cust_id: isDeleteOpenModal,
      };
      const res = await dispatch(custWhiteListDelete(payload)).unwrap();
      responseToaster(res);
      setFilter({
        ...filter,
      });
      setIsDeleteOpenModal(null);
      setDeleteLoading(false);
    } catch (err) {
      setDeleteLoading(false);
    }
  };

  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);

  const onGetCustWhiteList = async () => {
    try {
      setIsLoading(true);
      const { isFilter, cust_id, ...rest } = filter;
      const payload = {
        filter_data: {
          cust_id
        },
        ...rest,
      };
      const res = await dispatch(custWhiteListGet(payload)).unwrap();
      if (filter?.isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("custWhitelistInfo-list")) {
      onGetCustWhiteList();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);


  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...FILTER,
      isFilter: true,
    });
  };

  if (!role.includes("custWhitelistInfo-list")) {
    return;
  }

  return (
    <div className="merchant-main-div">
      <Card>
        <CardHeader className="d-flex align-items-center w-100 justify-content-between">
          <Form
            className="d-flex my-xl-auto right-content align-items-end button-space flex-wrap "
            onSubmit={handleSubmit(onSubmit)}
          >
            <ControlledInput
              name="cust_id"
              placeholder="Enter Customer ID"
              label="Customer ID"
              control={control}
            />
            <Button type="submit" color="primary">
              Apply
            </Button>
            <Button
              type="button"
              color="danger "
              onClick={() => {
                reset();
                setFilter({ ...FILTER });
              }}
            >
              Clear
            </Button>
          </Form>
          {role.includes("custWhitelistInfo-add") ? (
            <Button
              title="Add CustWhiteList"
              color="primary"
              onClick={toggleAddModal}
            >
              Add
            </Button>
          ) : null}
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={custWhiteList || []}
          isData={!!custWhiteList?.length}
          isExpandable={false}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {custWhiteList?.map((val) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
        </Table>
      </Card>
      <AddCustWhiteListModal
        isOpen={isAddModalOpen}
        onHide={toggleAddModal}
        onGet={() => setFilter({ ...filter })}
      />
      <DeleteModal
        title="Delete Whitelisted Customer"
        content="Are you sure you want to remove this customer from the whitelist ?"
        isOpen={!!isDeleteOpenModal}
        onDoneClick={() => onDelete()}
        onCancelClick={() => {
          setIsDeleteOpenModal(null)
          setDeleteLoading(false)
        }}
        isButtonLoading={deleteLoading}
      />
    </div>
  );
};

export default CustWhiteList;
