import { ADD_CUSTWHITELIST, DELETE_CUSTWHITELIST, GET_CUSTWHITELIST, UPDATE_CUSTWHITELIST } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getCustWhiteList = (payload) => {
  return apiInstance.post(GET_CUSTWHITELIST, payload);
};

export const addCustWhiteList = (payload) => {
  return apiInstance.post(ADD_CUSTWHITELIST, payload);
};

export const editCustWhiteList = (payload) => {
  return apiInstance.post(UPDATE_CUSTWHITELIST, payload);
};

export const deleteCustWhiteList = (payload) => {
  return apiInstance.post(DELETE_CUSTWHITELIST, payload);
};
