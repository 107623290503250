import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  payoutBlockInfoGet,
  usePayoutBlockInfo,
} from "../../../redux/slices/payoutBlockInfoSlice";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import { responseToaster } from "../../../helperFunctions";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import { DROPDOWN_ALL_VALUE, FILTER } from "../../../constant";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { useRole } from "../../../redux/slices/authSlice";
import PayoutBlockInfoModal from "../../../components/Custom/Modals/PayoutBlockInfo/PayoutBlockInfoModal";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import { payoutBlockInfoGetUpdate } from "../../../redux/slices/payoutBlockInfoSlice";
import { setPayoutBlock } from "../../../redux/slices/payoutBlockInfoSlice";

const isBlockOptions = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 0,
    label: "No",
  },
];

const PayoutBlockInfo = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const cutomerManagementItems = usePayoutBlockInfo();

  const initialValues = {
    isBlock: "All",
    acNumber: "",
  };

  const [filter, setFilter] = useState({ ...FILTER, isToast: false });
  const [filterColumns, setFilterColumns] = useState([]);
  const role = useRole("Payout Block Info");

  const onUpdateStatus = async (payload) => {
    try {
      setStatusLoading(payload?.id);
      const res = await dispatch(payoutBlockInfoGetUpdate(payload)).unwrap();
      const index = cutomerManagementItems?.data?.findIndex(
        (item) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...cutomerManagementItems?.data];
        newData[index] = { ...newData[index], is_block: payload?.isBlock };
        dispatch(
          setPayoutBlock({
            ...cutomerManagementItems?.data,
            data: newData,
          })
        );
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  const columns = [
    {
      title: "Account Number",
      name: "account_number",
    },
    {
      title: "IS BLOCK",
      name: "is_block",
      Cell: (data) => {
        return (
          <>
            {role.includes("payoutBlockInfo-update") ? (
              <ToggleSwitch
                isLoading={statusLoading === data?.id}
                value={data?.is_block ? 1 : 0}
                className={`${statusLoading ? "disabled" : ""}`}
                onChange={async (value, e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  try {
                    const payload = {
                      isBlock: value,
                      acNumber: data?.account_number,
                      id: data?.id,
                    };
                    await onUpdateStatus(payload);
                  } catch (err) { }
                }}
              />
            ) : null}
          </>
        );
      },
    },
    {
      title: "CREATED AT",
      name: "created_at",
      Cell: (data) => {
        return data?.created_at_ist || data?.created_at;
      },
    },
  ];

  const onGet = async () => {
    setIsLoading(true);
    try {
      const {
        isFilter,
        isToast,
        page_no,
        limit,
        filter: dropdown,
        ...rest
      } = filter;
      const payload = isFilter
        ? {
          filter_data: {
            ...rest,
          },
          page_no,
          limit,
        }
        : {
          page_no,
          limit,
        };
      const res = await dispatch(payoutBlockInfoGet(payload)).unwrap();
      if (isToast) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("payoutBlockInfo-list")) {
      onGet();
    }
  }, [filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...FILTER,
      isFilter: true,
      isToast: true,
    });
  };

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <>
      {role.includes("payoutBlockInfo-list") ? (
        <>
          <div className="support-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <h4 className="main-title">
                    <b>Payout Block Info</b>
                  </h4>
                  {role?.includes("payoutBlockInfo-add") ? (
                    <Button
                      color="primary"
                      title="Bank Statement"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Add Payout Block Info
                    </Button>
                  ) : null}
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  className="d-flex my-xl-auto right-content align-items-end button-space"
                  style={{ flexWrap: "wrap" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <ControlledInput
                    placeholder="Enter Account Number"
                    name="acNumber"
                    label="Account Number"
                    control={control}
                  />
                  <ControlledDropDown
                    name="isBlock"
                    label="Is Block"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={isBlockOptions}
                    control={control}
                  />
                  <Button type="submit" color="primary">
                    Apply
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => {
                      reset();
                      setFilter({ ...FILTER, isFilter: false, isToast: false });
                    }}
                  >
                    Clear
                  </Button>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={cutomerManagementItems?.data || []}
                isData={!!cutomerManagementItems?.data?.length}
                filterColumns={filterColumns}
                count={cutomerManagementItems?.total_item || 0}
                pagination={filter}
                isExpandable={false}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                    isToast: false,
                  });
                }}
              >
                {cutomerManagementItems?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
      <PayoutBlockInfoModal
        isModalOpen={isModalOpen}
        onGet={() => onGet()}
        onCloseModal={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default PayoutBlockInfo;
