import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableRow } from "../../../components/Table";
import {
  blockInfoGet,
  useBlockInfoState,
} from "../../../redux/slices/blockInfoSlice";
import { TRA_FILTER } from "../../../constant";
import { useRole } from "../../../redux/slices/authSlice";
import BlockInfoModal from "../../../components/Custom/Modals/BlockInfo/BlockInfoModal";
import { FaRegEye } from "react-icons/fa";
import BlockInfoShowModal from "./BlockInfoShowModal";

const BlockInfo = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(TRA_FILTER);
  const blockInfo = useBlockInfoState();
  const blockInfoState = Object.entries?.(blockInfo?.data || {})?.map?.(
    ([k, v]) => {
      const { merchant_details, ...rest } = v
      return {
        merchantId: k,
        merchantName: v?.merchant_details?.merchant_name,
        values: rest,
      };
    }
  );
  const role = useRole("Block Info");
  const { blockInfoIsLoading } = useSelector((state) => state?.blockInfo);
  const [filterColumns, setFilterColumns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);

  const columns = [
    {
      title: "Merchant",
      name: "merchantName",
    },
    {
      title: "ACTION",
      name: "action",
      Cell: (data) => {
        return (
          <>
            <div className="d-flex flex-row gap-50">
              {role?.includes("blockInfo-list") ? (
                <Button
                  color="primary"
                  className="btn-smallsize"
                  onClick={() => setIsShowModalOpen(data)}
                  title="Show Info"
                >
                  <FaRegEye />
                </Button>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const BlockInfoGet = async () => {
    try {
      const { isFilter, page_no, limit, ...rest } = filter;
      const payload = isFilter
        ? { filter_data: {}, page_no, limit }
        : {
          page_no,
          limit,
        };
      await dispatch(blockInfoGet(payload)).unwrap();
    } catch (err) { }
  };

  useEffect(() => {
    if (role?.includes?.("blockInfo-list")) {
      BlockInfoGet();
    }
  }, [filter]);

  if (!role.includes?.("blockInfo-list")) {
    return;
  }

  return (
    <>
      <Card>
        <CardHeader className="flex-column align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Block Info</b>
            </h4>
            {role?.includes?.("blockInfo-add") ? (
              <Button
                color="primary"
                title="Bank Statement"
                onClick={() => setIsModalOpen(true)}
              >
                Add Block Info
              </Button>
            ) : null}
          </div>
        </CardHeader>
        <Table
          columns={columns}
          isLoading={blockInfoIsLoading}
          data={blockInfoState || []}
          isData={!!blockInfoState?.length}
          filterColumns={filterColumns}
          count={blockInfo?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {blockInfoState?.map?.((item) => {
            return (
              <TableRow
                columns={columns}
                item={item}
                filterColumns={filterColumns}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </Card>
      <BlockInfoModal
        isModalOpen={isModalOpen}
        onGet={() => BlockInfoGet()}
        onCloseModal={() => {
          setIsModalOpen(false);
        }}
      />
      <BlockInfoShowModal
        data={isShowModalOpen}
        onGet={() => BlockInfoGet()}
        onClose={() => {
          setIsShowModalOpen(false);
        }}
      />
    </>
  );
};

export default BlockInfo;
