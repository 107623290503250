import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getPayoutBlockInfo } from "../services/payoutBlockInfoServices";
import { getPayoutBlockInfoUpdate } from "../services/payoutBlockInfoServices";
import { getPayoutBlockInfoAdd } from "../services/payoutBlockInfoServices";

const initialState = {
  items: {},
};

export const payoutBlockInfoGet = createAsyncThunk(
  "/payoutBlockInfoGet",
  async (payload) => {
    return await getPayoutBlockInfo(payload);
  }
);

export const payoutBlockInfoGetUpdate = createAsyncThunk(
  "/payoutBlockInfoUpdate",
  async (payload) => {
    return await getPayoutBlockInfoUpdate(payload);
  }
);

export const payoutBlockInfoGetAdd = createAsyncThunk(
  "/payoutBlockInfoAdd",
  async (payload) => {
    return await getPayoutBlockInfoAdd(payload);
  }
);

const payoutBlockInfoSlice = createSlice({
  name: "payoutBlockInfoSlice",
  initialState,
  reducers: {
    clearPayoutBlockInfo: () => initialState,
    setPayoutBlock: (state, { payload }) => {
      state.items = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(payoutBlockInfoGet.fulfilled, (state, action) => {
      state.items = action.payload;
    });
  },
});
export default payoutBlockInfoSlice.reducer;
export const { clearPayoutBlockInfo,setPayoutBlock } = payoutBlockInfoSlice.actions;

export const selectPayoutBlockInfo = (state) => {
  return state.payoutBlockInfo.items;
};
export const usePayoutBlockInfo = () => {
  const items = useSelector(selectPayoutBlockInfo);
  return useMemo(() => items, [items]);
};
