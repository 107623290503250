import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { capitalCaseLabel, responseToaster } from "../../../helperFunctions";
import DeleteModal from "../../../components/Custom/Modals/DeleteModal/DeleteModal";
import { DeleteBlockInfo } from "../../../redux/slices/blockInfoSlice";
import { useDispatch } from "react-redux";
import { useRole } from "../../../redux/slices/authSlice";

const keys = {
  cust_state: "Customer State",
  cust_city: "Customer City"
}

const BlockInfoShowModal = (props) => {
  const { data, onClose, onGet } = props;
  const [deleteId, setDeleteId] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setisModalOpen] = useState({});
  const [deleteSomething, setDeleteSomething] = useState(false);
  const dispatch = useDispatch();
  const role = useRole("Block Info");

  useEffect(() => {
    setisModalOpen(data);
  }, [data?.merchantId]);

  const onHide = () => {
    onClose();
    setDeleteId({});
    setIsLoading(false);
    setisModalOpen({});
    setDeleteSomething(false);
    if (deleteSomething) {
      onGet?.();
    }
  };
  const onDelete = async () => {
    try {
      setIsLoading(true);
      const payload = {
        merchantId: isModalOpen?.merchantId,
        columnName: deleteId?.key,
        blockData: deleteId?.v,
      };
      const res = await dispatch(DeleteBlockInfo(payload)).unwrap();
      responseToaster(res);
      if (res?.status) {
        setDeleteSomething(true);
        const updatedValues = {
          ...isModalOpen.values,
          [deleteId.key]: isModalOpen?.values?.[deleteId?.key]?.filter?.(
            (item) => item !== deleteId?.v
          ),
        };

        if (updatedValues?.[deleteId?.key]?.length === 0) {
          delete updatedValues?.[deleteId?.key];
        }

        setisModalOpen({
          ...isModalOpen,
          values: updatedValues,
        });
        setDeleteId({});
        if (!Object?.keys?.(updatedValues || {})?.length) {
          onHide()
          onGet?.();
        }
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  if (!isModalOpen) {
    return;
  }

  return (
    <>
      <Modal
        className="add-manual-payout-modal modal-lg"
        isOpen={isModalOpen}
        toggle={onHide}
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-0">Block Info for {data?.merchantName}</h3>
          <i
            onClick={() => {
              onHide();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body" style={{ maxHeight: '80vh', overflow: "scroll" }}>
          {Object?.entries?.(isModalOpen?.values || {})?.map?.(([key, value]) => (
            <div key={key}>
              <div className="mb-1">
                <b className="themecolor mb-1" style={{ minWidth: "135px" }}>
                  {keys?.[key] || capitalCaseLabel?.(key)}
                </b>
                <div className="w-100 block_info">
                  {value?.map?.((v) => {
                    return (
                      <div className="d-flex align-items-center justify-content-between border p-25">
                        <span>{v}</span>
                        {role?.includes("blockInfo-delete") ? <Button
                          color="danger"
                          onClick={() => {
                            setDeleteId({ key, v });
                          }}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </Button> : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button
              className="graybutton"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <DeleteModal
        title="Delete Block Info"
        content="Are you sure you want to delete this block info ?"
        isOpen={Object?.keys?.(deleteId || {})?.length}
        onDoneClick={() => onDelete()}
        onCancelClick={() => setDeleteId({})}
        isButtonLoading={isLoading}
      />
    </>
  );
};

export default BlockInfoShowModal;
