import { ADD_BLOCK_INFO, DELETE_BLOCK_INFO, GET_BLOCK_INFO } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getBlockInfo = (payload) => {
  return apiInstance.post(GET_BLOCK_INFO, payload);
};
export const addBlockInfo = (payload) => {
  return apiInstance.post(ADD_BLOCK_INFO, payload);
};
export const deleteBlockInfo = (payload) => {
  return apiInstance.post(DELETE_BLOCK_INFO, payload);
};