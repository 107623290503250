import MerchantDashboardLogs from "../../../components/Custom/Modals/MerchantDashboardLogs/MerchantDashboardLogs";
import AddManualPayoutModal from "../../../components/Custom/Modals/AddManualPayoutModal/AddManualPayoutModal";
import MerchantWhitelistIp from "../../../components/Custom/Modals/MerchantWhitelistIp/MerchantWhitelistIp";
import AddManualPayin from "../../../components/Custom/Modals/AddManualPayin/AddManualPayin";
import ViewStatement from "../../../components/Custom/Modals/ViewStatement/ViewStatement";
import ResetPassword from "../../../components/Custom/Modals/ResetPassword/ResetPassword";
import {
  Button,
  Card,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import {
  Merchant_Allow_Password_Update,
  Merchant_Details_Update,
  Merchant_IsDesktopEnable_Update,
  Merchant_IsFlow_Update,
  Merchant_Live_Settlement,
  useMerchantPayIn,
} from "../../../redux/slices/merchantSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import MerchantReleaseSettlement from "../../../components/Custom/Modals/MerchantReleaseSettlement/MerchantReleaseSettlement";
import { Controller, useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { AccountStatusOptions, trueFalseOptions } from "./mock";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledToggleSwitch from "../../../components/Custom/Forms/Controller/ControlledToggleSwitch";
import { useRole } from "../../../redux/slices/authSlice";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import moment from "moment";
import EyeOff from "../../../assets/images/pages/eye-off.svg";
import EyeOn from "../../../assets/images/pages/eye-on.svg";

const MerchantForm = (props) => {
  const { merchantData, setMerchantData, isLoading, setIsLoading } = props;

  const { id } = useParams();
  const dispatch = useDispatch();
  const role = useRole("Merchant");

  const merchantPayIn = useMerchantPayIn();

  const [isSwitchDisabled, setswitchDisabled] = useState(false);
  const [isSwitchDisabled1, setswitchDisabled1] = useState(false);
  const [pwdUpdateLoading, setPwdUpdateLoading] = useState(false);
  const [t1Settlement, setT1Settlement] = useState(false);
  const [addManualPayoutModal, setAddManualPayoutModal] = useState(false);
  const [addManualPayinModal, setAddManualPayinModal] = useState(false);
  const [viewStatement, setViewStatement] = useState(false);
  const [releseSettlement, setreleseSettlement] = useState(false);
  const [merchantDashboardLog, setMerchantDashboardLog] = useState(false);
  const [merchantWhiteIp, setMerchantWhiteIp] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const schema = yup.object({
    acStatus: yup.string().required("Please enter a account status"),
    minLimit: yup
      .number()
      .required("Please enter a min limit")
      .typeError("Please enter a min limit"),
    maxLimit: yup
      .number()
      .required("Please enter a max limit")
      .typeError("Please enter a max limit"),
    minPayoutLimit: yup
      .number()
      .required("Please enter a min limit")
      .typeError("Please enter a min limit"),
    maxPauoutLimit: yup
      .number()
      .required("Please enter a max limit")
      .typeError("Please enter a max limit"),
    depositEnable: yup
      .number()
      .required("Please enter a is deposit enabled")
      .typeError("Please enter a is deposit enabled"),
    withdrawalEnable: yup
      .number()
      .required("Please enter a is withdrawal enabled")
      .typeError("Please enter a is withdrawal enabled"),
    IsFailedWebhookRequired: yup
      .number()
      .required("Please enter a is failed webhook")
      .typeError("Please enter a is failed webhook"),
    IsEnableBrowserCheck: yup
      .number()
      .required("Please enter a is enabled browser check")
      .typeError("Please enter a is enabled browser check"),
    IsEnablewithdrawalBalanceCheck: yup
      .number()
      .required("Please enter a is enabled withdrawal balance check")
      .typeError("Please enter a is enabled withdrawal balance check"),
    IsDashboardwithdrawalEnable: yup
      .number()
      .required("Please enter a is dashboard withdrawal enabled")
      .typeError("Please enter a is dashboard withdrawal enabled"),
    IsAutoApprovedwithdrawal: yup
      .number()
      .required("Please enter a is auto approved withdrawal")
      .typeError("Please enter a is auto approved withdrawal"),
    ShowCustomerDetailsPage: yup
      .number()
      .required("Please enter a show customer details page")
      .typeError("Please enter a show customer details page"),
    IsCustomerDetailsRequired: yup
      .number()
      .required("Please enter a is customer details required")
      .typeError("Please enter a is customer details required"),
    isIntentInResponse: yup
      .number()
      .required("Please enter a is intent response")
      .typeError("Please enter a is intent response"),
    IsSettlementEnable: yup
      .number()
      .required("Please enter a is settlement enabled")
      .typeError("Please enter a is settlement enabled"),
    depositAutoFees: yup
      .number()
      .required("Please enter a deposit auto fees")
      .typeError("Please enter a deposit auto fees"),
    depositManualFees: yup
      .number()
      .required("Please enter a deposit manual fees")
      .typeError("Please enter a deposit manual fees"),
    withdrawalFees: yup
      .number()
      .required("Please enter a withdrawal fees")
      .typeError("Please enter a withdrawal fees"),
    withdrawalAssociateFees: yup
      .number()
      .required("Please enter a withdrawal associate fees")
      .typeError("Please enter a withdrawal associate fees"),
    depositAssociateFees: yup
      .number()
      .required("Please enter a deposit associate fees")
      .typeError("Please enter a is deposit associate feesnse"),
    SettlementCycle: yup
      .number()
      .required("Please enter a settlement cycle")
      .typeError("Please enter a is settlement cyclense"),
    withdrawalDelayedTime: yup
      .string()
      .required("Please enter a withdrawal delayed time"),
    OldUsersDays: yup.string().required("Please enter a old user days"),
    isVerifyUpiFirst: yup
      .number()
      .required("Please enter a is verify upi first")
      .typeError("Please enter a is verify upi first"),
    isPlayerInfoRequired: yup
      .number()
      .required("Please enter a is player info required")
      .typeError("Please enter a is player info required"),
    isLevelCustoActive: yup
      .number()
      .required("Please enter a is level custo active")
      .typeError("Please enter a is level custo active"),
    isLevelActive: yup
      .number()
      .required("Please enter a is level active")
      .typeError("Please enter a is level active"),
    isLevelHidActive: yup
      .number()
      .required("Please enter a is level hide active")
      .typeError("Please enter a is level hide active"),
    isAllowExpire: yup
      .number()
      .required("Please enter a is allow expire")
      .typeError("Please enter a is allow expire"),
    isFlowV2: yup
      .number()
      .required("Please enter a flow v2")
      .typeError("Please enter a flow v2"),
    isDesktopEnable: yup
      .number()
      .required("Please enter a desktop enable")
      .typeError("Please enter a desktop enable"),
    checkoutExpireTimeInSec: yup
      .number()
      .required("Please enter a checkout exire time in sec")
      .typeError("Please enter a checkout exire time in sec"),
    isRequiredHash: yup
      .number()
      .required("Please enter a is required hash")
      .typeError("Please enter a is required hash"),
  });

  const onCloseModal = () => {
    setAddManualPayoutModal(false);
  };
  const onCloseAddManualPayinModal = () => {
    setAddManualPayinModal(false);
  };
  const onCloseViewStatement = () => {
    setViewStatement(false);
  };
  const onCloseReleseSettlement = () => {
    setreleseSettlement(false);
  };
  const onCloseMerchantDashboardLog = () => {
    setMerchantDashboardLog(false);
  };
  const onCloseMerchantWhiteIp = () => {
    setMerchantWhiteIp(false);
  };
  const onCloseResetPassword = () => {
    setResetPassword(false);
  };

  const initialValues = {
    depositEnable: 0,
    depositManualFees: "",
    depositNotify: "",
    depositAssociateFees: "",
    depositAutoFees: "",
    withdrawalEnable: "",
    IsEnablewithdrawalBalanceCheck: "",
    withdrawalWebhook: "",
    IsDashboardwithdrawalEnable: 0,
    withdrawalDelayedTime: "",
    withdrawalFees: "",
    acStatus: "",
    minLimit: "",
    maxLimit: "",
    minPayoutLimit: "",
    maxPauoutLimit: "",
    IsFailedWebhookRequired: 0,
    IsEnableBrowserCheck: 0,
    SettlementCycle: 0,
    OldUsersDays: "",
    CheckoutColor: "",
    CheckoutThemeUrl: "",
    IsAutoApprovedwithdrawal: 0,
    ShowCustomerDetailsPage: 0,
    IsCustomerDetailsRequired: 0,
    IsSettlementEnable: 0,
    merchantBouncerUrl: "",
    withdrawalAssociateFees: "",
    checkOutUrl: "",
    isIntentInResponse: 0,
    isVerifyUpiFirst: 0,
    isRequiredHash: 0,
    isPlayerInfoRequired: 0,
    isLevelCustoActive: 0,
    isLevelActive: 0,
    isLevelHidActive: 0,
    isAllowExpire: 0,
    isFlowV2: 0,
    isDesktopEnable: 0,
    isAllowPwdUpdate: 0,
    updatedPassword: null,
    checkoutExpireTimeInSec: "",
    isEnableCostumerLevel: 0,
    t1Settlement: 0,
  };

  const onSubmit = async () => {
    try {
      setBtnLoad(true);
      const values = getValues();
      const payload = {
        ...values,
        byHid: values?.radio1 === "hid" ? 1 : 0,
        byCid: values?.radio1 === "cid" ? 1 : 0,
        dailyAt: values?.dailyAt
          ? moment(values?.dailyAt).format("HH:mm:ss")
          : null,
        merchantId: id,
      };
      const res = await dispatch(Merchant_Details_Update(payload)).unwrap();
      responseToaster(res);
      const clonedPayload = {
        ...payload,
        dailyAt: values?.dailyAt,
      };
      setMerchantData(clonedPayload);
      setBtnLoad(false);
    } catch (err) {
      setBtnLoad(false);
      console.log("err", err);
    }
  };

  const onUpdateIsflow = async (value) => {
    try {
      setswitchDisabled(true);
      const payload = {
        merchantId: id,
        isFlowV2: value,
      };
      const res = await dispatch(Merchant_IsFlow_Update(payload)).unwrap();

      responseToaster(res);
      const values = getValues();
      setMerchantData({ ...values, ...payload });
      setswitchDisabled(false);
    } catch (err) {
      setswitchDisabled(false);
    }
  };
  const onUpdateLiveSettlement = async (value) => {
    try {
      setT1Settlement(true);
      const payload = {
        merchantId: id,
        t1Settlement: value,
      };
      const res = await dispatch(Merchant_Live_Settlement(payload)).unwrap();

      responseToaster(res);
      const values = getValues();
      setMerchantData({ ...values, ...payload });
      setT1Settlement(false);
    } catch (err) {
      setT1Settlement(false);
    }
  };

  const onUpdateIsDesktopEnable = async (value) => {
    try {
      setswitchDisabled1(true);
      const payload = {
        merchantId: id,
        isDesktopEnable: value,
      };
      const res = await dispatch(
        Merchant_IsDesktopEnable_Update(payload)
      ).unwrap();

      responseToaster(res);
      const values = getValues();
      setMerchantData({ ...values, ...payload });
      setswitchDisabled1(false);
    } catch (err) {
      setswitchDisabled1(false);
    }
  };

  const onAllowPwdUpdate = async (value) => {
    try {
      setPwdUpdateLoading(true);
      const payload = {
        merchantId: id,
        isAllowPwdUpdate: value,
      };
      const res = await dispatch(
        Merchant_Allow_Password_Update(payload)
      ).unwrap();

      responseToaster(res);
      const values = getValues();
      setMerchantData({ ...values, ...payload });
      setPwdUpdateLoading(false);
    } catch (err) {
      setPwdUpdateLoading(false);
    }
  };

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (merchantData) {
      Object.entries(merchantData || {})?.forEach(([key, item]) => {
        setValue(key, item);
      });
    }
  }, [merchantData]);

  return (
    <>
      {role.includes("merchant-update") ? (
        <>
          <Card>
            <CardHeader style={{ display: "unset" }}>
              <div className="d-flex gap-1 flex-wrap">
                <Button
                  color={
                    addManualPayoutModal
                      ? "primary"
                      : "btn btn-outline-secondary"
                  }
                  className="filterbtn"
                  onClick={() => setAddManualPayoutModal(true)}
                >
                  Add Manual Withdrawal
                </Button>
                <Button
                  color={
                    addManualPayinModal
                      ? "primary"
                      : "btn btn-outline-secondary"
                  }
                  className="filterbtn"
                  onClick={() => setAddManualPayinModal(true)}
                >
                  Add Manual Deposit
                </Button>
                <Button
                  color={
                    viewStatement ? "primary" : "btn btn-outline-secondary"
                  }
                  className="filterbtn"
                  onClick={() => setViewStatement(true)}
                >
                  View Statement
                </Button>
                <Button
                  color={
                    viewStatement ? "primary" : "btn btn-outline-secondary"
                  }
                  className="filterbtn"
                  onClick={() => setreleseSettlement(true)}
                >
                  Release Settlement
                </Button>
                <Button
                  color={
                    merchantDashboardLog
                      ? "primary"
                      : "btn btn-outline-secondary"
                  }
                  className="filterbtn"
                  onClick={() => setMerchantDashboardLog(true)}
                >
                  View Dashboard Logs
                </Button>
                <Button
                  color={
                    merchantWhiteIp ? "primary" : "btn btn-outline-secondary"
                  }
                  className="filterbtn"
                  onClick={() => setMerchantWhiteIp(true)}
                >
                  View Whitelist lp
                </Button>
                {merchantPayIn?.merchantPermission === 1 ? (
                  <Button
                    color={
                      resetPassword ? "primary" : "btn btn-outline-secondary"
                    }
                    className="filterbtn"
                    onClick={() => setResetPassword(true)}
                  >
                    Reset Password
                  </Button>
                ) : null}
              </div>
            </CardHeader>
          </Card>
        </>
      ) : null}
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={` ${isLoading ? "opacity-25" : ""} detail-lable`}
      >
        <Card>
          <CardHeader style={{ display: "unset" }}>
            <div>
              <h4>DEPOSIT</h4>
              <div className="row border-bottom pb-2">
                <ControlledDropDown
                  name="depositEnable"
                  className="col-3"
                  label="Is Deposit Enable"
                  isDefaultOption={false}
                  options={trueFalseOptions}
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="depositManualFees"
                  className="col-3"
                  type="number"
                  label="Deposit Manual Fees"
                  placeholder="Enter Deposit Manual Fees"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="depositNotify"
                  className="col-3"
                  type="type"
                  label="Deposit Webhook"
                  placeholder="Enter Deposit Webhook"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="depositAssociateFees"
                  className="col-3"
                  type="number"
                  label="Deposit Associate Fees"
                  placeholder="Enter Deposit Associate Fees"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="depositAutoFees"
                  className="col-3"
                  type="number"
                  label="Deposit Auto Fees"
                  placeholder="Enter Deposit Auto Fees"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="minLimit"
                  className="col-3"
                  type="number"
                  label="Min PayIn Limit"
                  placeholder="Enter Min"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="maxLimit"
                  className="col-3"
                  type="number"
                  label="Max PayIn Limit"
                  placeholder="Enter Max"
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <h4 className="mt-2">WITHDRAWAL</h4>
            <div className="row border-bottom pb-2">
              <ControlledDropDown
                name="withdrawalEnable"
                className="col-3"
                label="Is Withdrawal Enable"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />

              <ControlledInput
                name="withdrawalWebhook"
                className="col-3"
                label="Withdrawal Webhook"
                placeholder="Enter Withdrawal Webhook"
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="IsDashboardwithdrawalEnable"
                className="col-3"
                label="Is Dashboard Withdrawal Enable"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="withdrawalDelayedTime"
                className="col-3"
                type="number"
                label="Withdrawal Delayed Time"
                placeholder="Enter Withdrawal Delayed Time"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="withdrawalFees"
                className="col-3"
                type="number"
                label="Withdrawal Fees"
                placeholder="Enter Withdrawal Fees"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="withdrawalAssociateFees"
                className="col-3"
                type="number"
                label="Withdrawal Associate Fees"
                placeholder="Enter Withdrawal Associate Fees"
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="IsAutoApprovedwithdrawal"
                className="col-3"
                label="Is Auto Approved Withdrawal"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="minPayoutLimit"
                className="col-3"
                type="number"
                label="Min PayOut Limit"
                placeholder="Enter Min"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="maxPauoutLimit"
                className="col-3"
                type="number"
                label="Max PayOut Limit"
                placeholder="Enter Max"
                errors={errors}
                control={control}
              />
            </div>
            <h4 className="mt-2">ACCOUNT SETTINGS</h4>
            <div className="row border-bottom pb-2">
              <ControlledDropDown
                name="acStatus"
                className="col-3"
                label="Account Status"
                isDefaultOption={false}
                options={AccountStatusOptions}
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="SettlementCycle"
                className="col-3"
                label="Settlement Cycle"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="IsSettlementEnable"
                className="col-3"
                label="Is Settlement Enable"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="merchantBouncerUrl"
                className="col-3"
                label="Merchant Bouncer Url"
                placeholder="Enter Merchant Bouncer Url"
                control={control}
              />
              <ControlledDatePicker
                name="dailyAt"
                className="col-3"
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                timeCaption="Time"
                timeIntervals={1}
                placeholder="Select Time"
                label="Daily At"
                control={control}
                errors={errors}
              />
              <div className="select-div col-3">
                <Label>T1 Settlement</Label>
                <ControlledToggleSwitch
                  name="t1Settlement"
                  className={`${t1Settlement ? "disabled opacity-50" : ""}`}
                  control={control}
                  onChange={(e) => {
                    onUpdateLiveSettlement(e);
                  }}
                />
              </div>
            </div>
            <h4 className="mt-2">API SETTINGS</h4>
            <div className="row border-bottom pb-2">
              <ControlledDropDown
                name="IsCustomerDetailsRequired"
                className="col-3"
                label="Is Customer Details Required"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="isPlayerInfoRequired"
                className="col-3"
                label="Is Player Info Required"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <div className="select-div col-3">
                <Label>Can Create Seamless Order</Label>
                <FormGroup
                  noMargin
                  tag="div"
                  className="text-start justify-content-start d-flex align-items-end col-3"
                >
                  <FormGroup check className="mb-0">
                    <div className={`pl-0`}>
                      <Input
                        type="radio"
                        value={1}
                        checked={watch("isIntentInResponse") === 1}
                        onChange={(e) => {
                          setValue("isIntentInResponse", 1);
                        }}
                      />
                      <Label className={`mt-0 form-check-label`}>Yes</Label>
                    </div>
                  </FormGroup>
                  <FormGroup check>
                    <div className={`form-check`}>
                      <Input
                        type="radio"
                        value={0}
                        checked={watch("isIntentInResponse") === 0}
                        onChange={(e) => {
                          setValue("isIntentInResponse", 0);
                        }}
                      />
                      <Label className={`mt-0 form-check-label`}>No</Label>
                    </div>
                  </FormGroup>
                </FormGroup>
              </div>
              <ControlledDropDown
                name="IsEnablewithdrawalBalanceCheck"
                className="col-3"
                label="Is Withdrawal Balance Check"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
            </div>
            <h4 className="mt-2">CHECKOUT SETTINGS</h4>
            <div className="row border-bottom pb-2">
              <ControlledDropDown
                name="IsEnableBrowserCheck"
                className="col-3"
                label="Is Enable Browser Check"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="CheckoutColor"
                className="col-3"
                label="Checkout Color"
                placeholder="Enter Checkout Color"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="CheckoutThemeUrl"
                className="col-3"
                label="Checkout Theme Url"
                placeholder="Enter Checkout Theme"
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="isLevelCustoActive"
                className="col-3"
                label="Is Level Customer Active"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="isLevelActive"
                className="col-3"
                label="Is Level Active"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="isLevelHidActive"
                className="col-3"
                label="Is Level Hide Active"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="isAllowExpire"
                className="col-3"
                label="Is Allow Expire"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="isEnableCostumerLevel"
                className="col-3"
                label="Is Enable Customer Level"
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="checkOutUrl"
                className="col-3"
                label="Checkout Url"
                placeholder="Enter Checkout Url"
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="checkoutExpireTimeInSec"
                className="col-3"
                type="number"
                label="Checkout Expire Time In Sec"
                placeholder="Enter Checkout Expire Time"
                control={control}
                errors={errors}
              />
              <ControlledDropDown
                name="ShowCustomerDetailsPage"
                className="col-3"
                label="Show Customer Details Page"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledDropDown
                name="isVerifyUpiFirst"
                className="col-3"
                label="Is Verify UPI First"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <div className="select-div col-3">
                <Label>Process by :</Label>
                <FormGroup
                  noMargin
                  tag="div"
                  className="text-start justify-content-start d-flex w-100 col-2 align-items-end"
                >
                  <FormGroup check className="mb-0">
                    <div className={`pl-0`}>
                      <Controller
                        control={control}
                        name="radio1"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="radio"
                            value="hid"
                            checked={field.value === "hid"}
                          />
                        )}
                      />
                      <Label className={`mt-0 form-check-label text-nowrap`}>
                        By hid
                      </Label>
                    </div>
                  </FormGroup>
                  <FormGroup check>
                    <div className={`form-check`}>
                      <Controller
                        control={control}
                        name="radio1"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="radio"
                            value="cid"
                            checked={field.value === "cid"}
                          />
                        )}
                      />
                      <Label className={`mt-0 form-check-label`}>By cid</Label>
                    </div>
                  </FormGroup>
                </FormGroup>
              </div>
              <div className="text-start justify-content-start gap-2 d-flex align-items-end col-3">
                <div className="select-div">
                  <Label>Flow V2</Label>
                  <ControlledToggleSwitch
                    name="isFlowV2"
                    className={`${isSwitchDisabled ? "disabled opacity-50" : ""
                      }`}
                    control={control}
                    onChange={(e) => {
                      onUpdateIsflow(e);
                    }}
                  />
                </div>
                <div className="select-div">
                  <Label>Is Desktop Enable</Label>
                  <ControlledToggleSwitch
                    name="isDesktopEnable"
                    className={`${isSwitchDisabled1 ? "disabled opacity-50" : ""
                      }`}
                    control={control}
                    onChange={(e) => {
                      onUpdateIsDesktopEnable(e);
                    }}
                  />
                </div>
              </div>
              <ControlledDropDown
                name="isRequiredHash"
                className="col-3"
                label="is Required Hash"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
            </div>
            {role?.some?.(r => ["merchant-password-update", "merchant-password-show"]?.includes?.(r)) ? <>
              <h4 className="mt-2 ">MERCHANT SETTINGS</h4>
              <div className="row border-bottom pb-2">
                {role.includes("merchant-password-update") ? <div className="select-div col-3">
                  <Label>Allow Password Update</Label>
                  <ControlledToggleSwitch
                    name="isAllowPwdUpdate"
                    className={`${pwdUpdateLoading ? "disabled opacity-50" : ""
                      }`}
                    control={control}
                    onChange={(e) => {
                      onAllowPwdUpdate(e);
                    }}
                  />
                </div> : null}
                {role.includes("merchant-password-show") && watch("updatedPassword") ? <ControlledInput
                  name="updatedPassword"
                  type={isShow ? "text" : "password"}
                  className="col-3"
                  disabled
                  label="Updated Password"
                  rightIcon={
                    <img
                      src={isShow ? EyeOn : EyeOff}
                      alt="Eye"
                      onClick={() => setIsShow(!isShow)}
                    />
                  }
                  control={control}
                /> : null}
              </div>
            </> : null}
            <h4 className="mt-2 ">OTHERS</h4>
            <div className="row border-bottom pb-2">
              <ControlledDropDown
                name="IsFailedWebhookRequired"
                className="col-3"
                label="Is Failed Webhook Req"
                isDefaultOption={false}
                options={trueFalseOptions}
                errors={errors}
                control={control}
              />
              <ControlledInput
                name="OldUsersDays"
                className="col-3"
                type="number"
                label="Old Users Days"
                placeholder="Enter Old Users Days"
                errors={errors}
                control={control}
              />
            </div>
          </CardHeader>
          <CardHeader>
            <div className="w-100 d-flex justify-content-end">
              <Button type="submit" color="primary" disabled={btnLoad}>
                Update
              </Button>
            </div>
          </CardHeader>
        </Card>
      </Form>

      <AddManualPayoutModal
        addManualPayoutModal={addManualPayoutModal}
        onCloseModal={onCloseModal}
        id={id}
      />
      <AddManualPayin
        addManualPayinModal={addManualPayinModal}
        onCloseAddManualPayinModal={onCloseAddManualPayinModal}
        id={id}
      />
      <ViewStatement
        viewStatement={viewStatement}
        onCloseViewStatement={onCloseViewStatement}
        id={id}
      />
      <MerchantReleaseSettlement
        releseSettlement={releseSettlement}
        onCloseReleseSettlement={onCloseReleseSettlement}
      />
      <MerchantDashboardLogs
        merchantDashboardLog={merchantDashboardLog}
        onCloseMerchantDashboardLog={onCloseMerchantDashboardLog}
        id={id}
      />
      <MerchantWhitelistIp
        id={id}
        merchantWhiteIp={merchantWhiteIp}
        onCloseMerchantWhiteIp={onCloseMerchantWhiteIp}
      />
      <ResetPassword
        resetPassword={resetPassword}
        onCloseResetPassword={onCloseResetPassword}
        id={id}
      />
    </>
  );
};

export default MerchantForm;
