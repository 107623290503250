import { PAYOUT_BLOCK_ADD, PAYOUT_BLOCK_INFO, PAYOUT_BLOCK_UPDATE } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getPayoutBlockInfo = (payload) => {
  return apiInstance.post(PAYOUT_BLOCK_INFO, payload);
};
export const getPayoutBlockInfoAdd = (payload) => {
  return apiInstance.post(PAYOUT_BLOCK_ADD, payload);
};
export const getPayoutBlockInfoUpdate = (payload) => {
  return apiInstance.post(PAYOUT_BLOCK_UPDATE, payload);
};
